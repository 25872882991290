// Required
@import "../../node_modules/bootstrap/scss/functions";

// Default variable overrides
$dark: #5282b0;
$light: #f2ce4d;
$primary: #2b4d74;
$text-dark: #1e3b4d;

$body-color: $text-dark;

$theme-colors: (
  "brand-nav": #5282b0,
  "brand-bg": #f2ce4d,
  "dark": $dark,
  "light": $light,
  "primary": $primary,
);

$cta: $dark;

$border-radius: .3rem;
$border-radius-sm: $border-radius;
$border-radius-lg: $border-radius;
$border-radius-xl: $border-radius;
$border-radius-xxl: $border-radius;


@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/variables-dark";
@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/utilities";

// Layout & components
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
// @import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
// @import "../../node_modules/bootstrap/scss/dropdown";
// @import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
// @import "../../node_modules/bootstrap/scss/card";
// @import "../../node_modules/bootstrap/scss/accordion";
// @import "../../node_modules/bootstrap/scss/breadcrumb";
@import "../../node_modules/bootstrap/scss/pagination";
// @import "../../node_modules/bootstrap/scss/badge";
@import "../../node_modules/bootstrap/scss/alert";
// @import "../../node_modules/bootstrap/scss/progress";
// @import "../../node_modules/bootstrap/scss/list-group";
// @import "../../node_modules/bootstrap/scss/close";
// @import "../../node_modules/bootstrap/scss/toasts";
// @import "../../node_modules/bootstrap/scss/modal";
// @import "../../node_modules/bootstrap/scss/tooltip";
// @import "../../node_modules/bootstrap/scss/popover";
// @import "../../node_modules/bootstrap/scss/carousel";
@import "../../node_modules/bootstrap/scss/spinners";
// @import "../../node_modules/bootstrap/scss/offcanvas";
// @import "../../node_modules/bootstrap/scss/placeholders";

// Helpers
@import "../../node_modules/bootstrap/scss/helpers";

// Utilities
@import "../../node_modules/bootstrap/scss/utilities/api";


@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700,400italic");

:root {
  --bs-font-sans-serif: "Source Sans Pro", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

// Import default bootstrap here so variable settings are applied
// and we can start overriding stuff.
// @import "../../node_modules/bootstrap/scss/bootstrap";

.bg-brand-gradient {
  background-image: linear-gradient(to right, #f3e078, $light);
}

html,
body {
  height: 100%;
}

// Restore BS4 behavior
a:not(.btn) {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.min-100 {
  min-height: 100%;
}

.max-x-sm {
  max-width: 540px;
}

.max-x-md {
  max-width: 720px;
}

.nav-item {
  font-size: 1.25rem;
}

code {
  // Partly a hack to ensure <code> inside HTML email template has no
  // foreground color and blends in with the rest of the text
  color: inherit;
}

/* Browser mockup code
 * Contribute: https://gist.github.com/jarthod/8719db9fef8deb937f4f
 * Live example: https://updown.io
 */

.browser-mockup {
  border-top: 2em solid rgba(230, 230, 230, 0.7);
  // box-shadow: 0 0.1em 1em 0 rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.75);
  position: relative;
  border-radius: 3px 3px 0 0;
}

.browser-mockup:before {
  display: block;
  position: absolute;
  content: "";
  top: -1.25em;
  left: 1em;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background-color: #f44;
  box-shadow:
    0 0 0 2px #f44,
    1.5em 0 0 2px #9b3,
    3em 0 0 2px #fb5;
}

.browser-mockup > * {
  display: block;
}

.browser-mockup {
  flex: 1;
}

// Super Clicky CTA Buttons
// https://thrivethemes.com/button-design/
.btn-primary {
  border-style: solid;
  border-width: 0 1px 4px 1px;
  font-size: 1.25rem; // same as lg form
  font-weight: inherit;
  text-transform: inherit;
  box-shadow: rgba(0, 0, 0, 0.3) 0 0 10px;
  // border-radius: 3px !important;
  background-color: $cta;
  border-bottom-width: 4px;
  border-color: $cta;
  border-bottom-color: darken($cta, 5%);
  color: #eee;
  // Avoid glitchy left/right borders on the bottom
  background-clip: border-box;
  border-right: none;
  border-left: none;
}

.btn-primary.disabled, .btn-primary[disabled] {
  border-bottom: none;
}

.btn-primary:not(.disabled):hover {
  background-color: lighten($cta, 5%);
  border-color: lighten($cta, 5%);
  border-bottom-color: darken($cta, 2.5%);
}

// Depressed state: decrease bottom border width from 4px to 2px
// and then add extra spacing on top to make it appear as if the
// button "sinks" into the page:
.btn-primary:not(.disabled):active {
  outline: 0 !important;
  border-top-width: 2px;
  border-bottom-width: 2px;
  background-color: lighten($cta, 5%) !important;
  border-color: lighten($cta, 5%) !important;
  border-bottom-color: darken($cta, 5%) !important;
}

.btn-primary:focus {
  // Fix "button background changes to white after click"
  background-color: $cta;
  border-bottom-width: 4px;
  border-color: $cta;
  border-bottom-color: darken($cta, 5%);
  color: #eee;
}

.python-powered {
  background: rgba(255, 255, 255, 0.5);
  padding: 0.3rem 0.4rem;
  height: 28px; // match twitter button
}

.captcha-container {
  // collapse empty space if we have a non-interactive CF captcha
  line-height: 0;
  font-size: 0;
}

.content-page {
  .container {
    max-width: 720px;
  }

  p {
    line-height: 1.4em;
    font-size: 21px;
    margin-bottom: 1.5em;
  }

  h1 {
    font-weight: bold;
    line-height: 1.1em;
  }

  h2 {
    margin-top: $paragraph-margin-bottom * 2;
    font-weight: bold;
  }

  h3 {
    margin-top: $paragraph-margin-bottom * 2;
    // font-weight: bold;
  }

  h4 {
    margin-top: $paragraph-margin-bottom * 2;
  }

  // Copied from _reboot.scss
  ol,
  ul,
  dl {
    margin-top: 0;
    margin-bottom: $paragraph-margin-bottom;
  }

  .small,
  small {
    font-size: 90%;
  }

  .display-4 {
    font-weight: 300 !important;
  }
}

// Stick Footer
body {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100vh;
}
.navbar {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.main-content {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
footer {
  border: 1px solid rgba(0, 0, 0, 0.125);
  bottom: 0;
  width: 100%;
  height: auto;
  line-height: 30px;

  // This is the magic incantation
  margin-top: auto;
}

// Text formatting
.text-wrap-balance {
  text-wrap: balance;
}

// Adjust spinner for btn primary
.spinner-border-primary {
  width: 1.5rem;
  height: 1.5rem;
  border-width: 0.15em;
}
